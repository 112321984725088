import { useRouter } from 'vue-router';

export function useJump() {
  const router = useRouter();
  let url = '';
  function jumpToOpenAccountManageApply(type) {
    url = router.resolve({
      name: 'OpenAccountManageApply',
      query: { type },
    }).href;
    openWindow();
  }
  /**
   * 跳到提交充值申请
   * @param pipeline
   * @param showDrawer
   */
  function jumpToOpenAccountManageSubmitRechargeApply(ids, platform) {
    url = router.resolve({
      name: 'OpenAccountManageSubmitRechargeApply',
      query: { ids, platform },
    }).href;
    openWindow();
  }
  /**
   * 跳到提交充值申请
   * @param pipeline
   * @param showDrawer
   */
  function jumpToOpenAccountManageRechargeDetail(record) {
    url = router.resolve({
      name: 'OpenAccountManageRechargeDetail',
      query: { id: record.id },
    }).href;
    openWindow();
  }
  /**
   * 跳到开户详情
   * @param record
   */
  function jumpToOpenAccountManageApplyDetail(record) {
    url = router.resolve({
      name: 'OpenAccountManageApplyDetail',
      query: { id: record.id },
    }).href;
    openWindow();
  }
  function jumpToOpenAccountManageAccountList() {
    url = router.resolve({
      name: 'OpenAccountManageAccountList',
    }).href;
    openWindow();
  }
  function jumpToOpenAccountManageRechargeList() {
    url = router.resolve({
      name: 'OpenAccountManageRechargeList',
    }).href;
    openWindow();
  }
  function backToHome() {
    router.push({ name: 'WorkbenchMyHome' });
  }
  function openWindow(type = '_self') {
    window.open(url, type);
  }
  return {
    backToHome,
    jumpToOpenAccountManageApply,
    jumpToOpenAccountManageSubmitRechargeApply,
    jumpToOpenAccountManageRechargeDetail,
    jumpToOpenAccountManageApplyDetail,
    jumpToOpenAccountManageAccountList,
    jumpToOpenAccountManageRechargeList,
  };
}
